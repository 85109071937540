import React, { Fragment, useEffect } from 'react';
import { Menu } from 'antd';
import {
  HomeOutlined,
  ScheduleOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  CrownOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  CloudServerOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  MailOutlined,
  FileExcelOutlined,
  PullRequestOutlined,
  AppstoreAddOutlined,
  SisternodeOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
  PlayCircleOutlined,
  FilePdfOutlined,
  ExperimentOutlined,
  ApartmentOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const items9 = [
  {
    label: <a href="/">Home</a>,
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'マイ・サーベイ',
    key: 'mysurveymenu',
    children: [
      {
        type: 'group',
        label: 'サーベイ情報',
        children: [
          {
            label: <a href="/mysurvey">マイサーベイ</a>,
            key: 'mySruvey',
            icon: <ScheduleOutlined />,
          },
        ],
      },
      {
        type: 'group',
        label: '実験室',
        children: [
          {
            label: <a href="/chatgpttest">Chat-GPT トレーニング</a>,
            key: 'researchRoom',
            icon: <ExperimentOutlined />,
          },
        ],
      },
    ],
  },
  {
    label: 'ユーザー管理',
    key: 'usermgrmenu',
    children: [
      {
        label: <a href="/usermgr">管理者</a>,
        key: 'usermgr',
        icon: <UserOutlined />,
      },
      {
        label: <a href="/respondentmgr">受講者</a>,
        key: 'respondentmgr',
        icon: <CrownOutlined />,
      },
      {
        label: <a href="/status360">360度診断回答状況</a>,
        key: 'status360',
        icon: <DeploymentUnitOutlined />,
      },
      {
        label: <a href="/csvuserupload">受講者 CSV Upload</a>,
        key: 'csvuserupload',
        icon: <CloudUploadOutlined />,
      },
      {
        label: <a href="/csvuserupdate">受講者 CSV Update with GSD, UVD</a>,
        key: 'csvuserupdate',
        icon: <CloudUploadOutlined />,
      },
      {
        label: (
          <a href="/csvuserupdateonly">受講者 CSV Update without GSD, UVD</a>
        ),
        key: 'csvuserupdateonly',
        icon: <CloudUploadOutlined />,
      },
      {
        label: <a href="/csvusercheck">CSV メールチェック</a>,
        key: 'csvusercheck',
        icon: <CloudUploadOutlined />,
      },
      {
        label: <a href="/csvrelationupload">360度 関係情報 CSV Upload</a>,
        key: 'csvrelationupload',
        icon: <CloudUploadOutlined />,
      },
      {
        label: <a href="/csvtokeninsert">サーベイチケット CSV 発行</a>,
        key: 'csvtokeninsert',
        icon: <CloudUploadOutlined />,
      },
      {
        label: <a href="/e3depmgr">E³結果閲覧範囲（部署）指定</a>,
        key: 'e3depmgr',
        icon: <ApartmentOutlined />,
      },
    ],
  },
  {
    label: '結果管理',
    key: 'surveydownloadmenu',
    children: [
      {
        label: <a href="/csvdownload">回答データCSVダウンロード</a>,
        key: 'csvdownload',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download10010">人事評価力診断結果一括ダウンロード(PDF)</a>
        ),
        key: 'download10010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download50010">
            モチベーション・トレンド診断結果一括ダウンロード(PDF)
          </a>
        ),
        key: 'download50010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download60010">
            フォロワーシップ診断結果一括ダウンロード(PDF)
          </a>
        ),
        key: 'download60010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download70010">女性活躍組織診断結果ダウンロード(PDF)</a>
        ),
        key: 'download70010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download80010">
            ダイバーシティ組織診断結果ダウンロード(PDF)
          </a>
        ),
        key: 'download80010',
        icon: <CloudDownloadOutlined />,
      },
      {
        type: 'group',
        label: 'E³-Survey結果',
        children: [
          {
            label: <a href="/download30010">E³-Survey結果ダウンロード(PDF)</a>,
            key: 'download30010',
            icon: <CloudDownloadOutlined />,
          },
          {
            label: (
              <a href="/exceldownload30010">
                E³-Survey集計データダウンロード(Excel)
              </a>
            ),
            key: 'exceldownload30010',
            icon: <FileExcelOutlined />,
          },
          {
            label: (
              <a href="/excelcomparedownload30010">
                E³-Survey経年比較集計データダウンロード(Excel)
              </a>
            ),
            key: 'excelcomparedownload30010',
            icon: <FileExcelOutlined />,
          },
          {
            label: <a href="/companysum30010">E³-Survey組織診断結果集計</a>,
            key: 'companysum30010',
            icon: <CloudServerOutlined />,
          },
        ],
      },
      {
        type: 'group',
        label: '人事評価力診断（外部データ）',
        children: [
          {
            label: <a href="/csvupload">外部CSV Upload</a>,
            key: 'epCSVUpload',
            icon: <CloudUploadOutlined />,
          },
          {
            label: <a href="/makereport">外部登録Report生成</a>,
            key: 'epMakeReport',
            icon: <FilePdfOutlined />,
          },
        ],
      },
    ],
  },
  {
    label: 'Data設定',
    key: 'datamgrmenu',
    children: [
      {
        label: <a href="/grouplist">企業グループ生成</a>,
        key: 'grouplist',
        icon: <UsergroupAddOutlined />,
      },
      {
        label: <a href="/seasonlist">サーベイシーズン生成</a>,
        key: 'seasonlist',
        icon: <SisternodeOutlined />,
      },
      {
        label: <a href="/makeqset">設問セット生成</a>,
        key: 'makeqset',
        icon: <AppstoreAddOutlined />,
      },
      {
        label: <a href="/assignqset">設問セット割り当て</a>,
        key: 'assignqset',
        icon: <PullRequestOutlined />,
      },
    ],
  },
  {
    label: 'メール送信',
    key: 'sendmailmenu',
    children: [
      {
        label: <a href="/mailtemplate">テンプレート管理</a>,
        key: 'mailtemplate',
        icon: <GroupOutlined />,
      },
      {
        label: <a href="/surveymailsend">サーベイ案内メール送信</a>,
        key: 'surveymailsend',
        icon: <MailOutlined />,
      },
      {
        label: <a href="/remindmailsend">リマインド・メール送信</a>,
        key: 'remindmailsend',
        icon: <MailOutlined />,
        danger: true,
      },
      {
        label: <a href="/csvmailsend">CSVからメール送信</a>,
        key: 'csvmailsend',
        icon: <FileExcelOutlined />,
      },
    ],
  },
  {
    label: '動画',
    key: 'videomenu',
    children: [
      {
        label: <a href="/videoupload">動画アップロード</a>,
        key: 'videoupload',
        icon: <VideoCameraAddOutlined />,
      },
      {
        label: <a href="/videomgr">動画管理</a>,
        key: 'videomgr',
        icon: <VideoCameraOutlined />,
      },
      // {
      //   label: <a href="/videodelivery">新規動画納品作成</a>,
      //   key: 'videodelivery',
      //   icon: <PlayCircleOutlined />,
      // },
      {
        label: <a href="/videodeliverymgr">動画納品管理</a>,
        key: 'videodeliverymgr',
        icon: <PlayCircleOutlined />,
      },
    ],
  },
];
const items2 = [
  {
    label: <a href="/">Home</a>,
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'マイ・サーベイ',
    key: 'mysurveymenu',
    children: [
      {
        type: 'group',
        label: 'サーベイ情報',
        children: [
          {
            label: <a href="/mysurvey">マイサーベイ</a>,
            key: 'mySruvey',
            icon: <ScheduleOutlined />,
          },
        ],
      },
      {
        type: 'group',
        label: '実験室',
        children: [
          {
            label: <a href="/chatgpttest">Chat-GPT トレーニング</a>,
            key: 'researchRoom',
            icon: <ExperimentOutlined />,
          },
        ],
      },
    ],
  },
  {
    label: 'ユーザー管理',
    key: 'usermgrmenu',
    children: [
      {
        label: <a href="/respondentmgr">受講者</a>,
        key: 'respondentmgr',
        icon: <CrownOutlined />,
      },
      {
        label: <a href="/status360">360度診断回答状況</a>,
        key: 'status360',
        icon: <DeploymentUnitOutlined />,
      },
      {
        label: <a href="/e3depmgr">E³結果閲覧範囲（部署）指定</a>,
        key: 'e3depmgr',
        icon: <ApartmentOutlined />,
      },
    ],
  },
  {
    label: '結果管理',
    key: 'surveydownloadmenu',
    children: [
      {
        label: <a href="/csvdownload">回答データCSVダウンロード</a>,
        key: 'csvdownload',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download10010">人事評価力診断結果一括ダウンロード(PDF)</a>
        ),
        key: 'download10010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download50010">
            モチベーション・トレンド診断結果一括ダウンロード(PDF)
          </a>
        ),
        key: 'download50010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download60010">
            フォロワーシップ診断結果一括ダウンロード(PDF)
          </a>
        ),
        key: 'download60010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download70010">女性活躍組織診断結果ダウンロード(PDF)</a>
        ),
        key: 'download70010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download80010">
            ダイバーシティ組織診断結果ダウンロード(PDF)
          </a>
        ),
        key: 'download80010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: <a href="/download30010">E³-Survey結果ダウンロード(PDF)</a>,
        key: 'download30010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/exceldownload30010">
            E³-Survey集計データダウンロード(Excel)
          </a>
        ),
        key: 'exceldownload30010',
        icon: <FileExcelOutlined />,
      },
      {
        label: (
          <a href="/excelcomparedownload30010">
            E³-Survey経年比較集計データダウンロード(Excel)
          </a>
        ),
        key: 'excelcomparedownload30010',
        icon: <FileExcelOutlined />,
      },
    ],
  },
  {
    label: 'メール送信',
    key: 'sendmailmenu',
    children: [
      {
        label: <a href="/mailtemplate">テンプレート管理</a>,
        key: 'mailtemplate',
        icon: <GroupOutlined />,
      },
    ],
  },
  {
    label: '動画',
    key: 'videomenu',
    children: [
      {
        label: <a href="/videoupload">動画アップロード</a>,
        key: 'videoupload',
        icon: <VideoCameraAddOutlined />,
      },
      {
        label: <a href="/videomgr">動画管理</a>,
        key: 'videomgr',
        icon: <VideoCameraOutlined />,
      },
      {
        label: <a href="/videodeliverymgr">動画納品管理</a>,
        key: 'videodeliverymgr',
        icon: <PlayCircleOutlined />,
      },
    ],
  },
];
//企業側管理者
const items3 = [
  {
    label: <a href="/">Home</a>,
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'マイ・サーベイ',
    key: 'mysurveymenu',
    children: [
      {
        type: 'group',
        label: 'サーベイ情報',
        children: [
          {
            label: <a href="/mysurvey">マイサーベイ</a>,
            key: 'mySruvey',
            icon: <ScheduleOutlined />,
          },
        ],
      },
    ],
  },
  {
    label: 'ユーザー管理',
    key: 'usermgrmenu',
    children: [
      {
        label: <a href="/corpmgr">受講者情報</a>,
        key: 'corpmgr',
        icon: <UsergroupAddOutlined />,
      },
      {
        label: <a href="/status360">360度診断回答状況</a>,
        key: 'status360',
        icon: <DeploymentUnitOutlined />,
      },
      {
        label: <a href="/e3depmgr">E³結果閲覧範囲（部署）指定</a>,
        key: 'e3depmgr',
        icon: <ApartmentOutlined />,
      },
    ],
  },
  {
    label: '結果管理',
    key: 'surveydownloadmenu',
    children: [
      // {
      //   label: <a href="/csvdownload">回答データCSVダウンロード</a>,
      //   key: 'csvdownload',
      //   icon: <CloudDownloadOutlined />,
      // },
      {
        label: (
          <a href="/download10010">人事評価力診断結果一括ダウンロード(PDF)</a>
        ),
        key: 'download10010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download50010">
            モチベーション・トレンド診断結果一括ダウンロード(PDF)
          </a>
        ),
        key: 'download50010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: (
          <a href="/download60010">
            フォロワーシップ診断結果一括ダウンロード(PDF)
          </a>
        ),
        key: 'download60010',
        icon: <CloudDownloadOutlined />,
      },
      {
        label: <a href="/download30010">E³-Survey結果ダウンロード(PDF)　</a>,
        key: 'download30010',
        icon: <CloudDownloadOutlined />,
      },
    ],
  },
];
//企業側管理者(E3)
const items4 = [
  {
    label: <a href="/">Home</a>,
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'マイ・サーベイ',
    key: 'mysurveymenu',
    children: [
      {
        type: 'group',
        label: 'サーベイ情報',
        children: [
          {
            label: <a href="/mysurvey">マイサーベイ</a>,
            key: 'mySruvey',
            icon: <ScheduleOutlined />,
          },
        ],
      },
    ],
  },
  {
    label: '結果管理',
    key: 'surveydownloadmenu',
    children: [
      {
        label: <a href="/download30010">E³-Survey結果ダウンロード(PDF)　</a>,
        key: 'download30010',
        icon: <CloudDownloadOutlined />,
      },
    ],
  },
];
//企業側管理者(360)
const items5 = [
  {
    label: <a href="/">Home</a>,
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'マイ・サーベイ',
    key: 'mysurveymenu',
    children: [
      {
        type: 'group',
        label: 'サーベイ情報',
        children: [
          {
            label: <a href="/mysurvey">マイサーベイ</a>,
            key: 'mySruvey',
            icon: <ScheduleOutlined />,
          },
        ],
      },
    ],
  },
  {
    label: 'ユーザー管理',
    key: 'usermgrmenu',
    children: [
      {
        label: <a href="/status360">360度診断回答状況</a>,
        key: 'status360',
        icon: <DeploymentUnitOutlined />,
      },
    ],
  },
];
//企業側管理者(E3+360)
const items6 = [
  {
    label: <a href="/">Home</a>,
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'マイ・サーベイ',
    key: 'mysurveymenu',
    children: [
      {
        type: 'group',
        label: 'サーベイ情報',
        children: [
          {
            label: <a href="/mysurvey">マイサーベイ</a>,
            key: 'mySruvey',
            icon: <ScheduleOutlined />,
          },
        ],
      },
    ],
  },
  {
    label: 'ユーザー管理',
    key: 'usermgrmenu',
    children: [
      {
        label: <a href="/status360">360度診断回答状況</a>,
        key: 'status360',
        icon: <DeploymentUnitOutlined />,
      },
    ],
  },
  {
    label: '結果管理',
    key: 'surveydownloadmenu',
    children: [
      {
        label: <a href="/download30010">E³-Survey結果ダウンロード(PDF)　</a>,
        key: 'download30010',
        icon: <CloudDownloadOutlined />,
      },
    ],
  },
];
const items1 = [
  {
    label: <a href="/">Home</a>,
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'マイ・サーベイ',
    key: 'mysurveymenu',
    children: [
      {
        type: 'group',
        label: 'サーベイ情報',
        children: [
          {
            label: <a href="/mysurvey">マイサーベイ</a>,
            key: 'mySruvey',
            icon: <ScheduleOutlined />,
          },
        ],
      },
    ],
  },
];

function LeftMenu(props) {
  // console.log({ props });
  let user = useSelector((state) => state.user);

  if (!user.userData) return null;
  const { isAdmin } = user.userData;
  const { role } = user.userData;
  // console.log(user.userData);
  if (role === 9) {
    //特権管理者メニュー
    return <Menu mode={props.mode} items={items9} />;
  } else if (role === 2) {
    //リ・カレント管理者メニュー
    return <Menu mode={props.mode} items={items2} />;
  } else if (role === 3) {
    //企業管理者メニュー
    return <Menu mode={props.mode} items={items3} />;
  } else if (role === 4) {
    //企業管理者メニュー
    return <Menu mode={props.mode} items={items4} />;
  } else if (role === 5) {
    //企業管理者メニュー
    return <Menu mode={props.mode} items={items5} />;
  } else if (role === 6) {
    //企業管理者メニュー
    return <Menu mode={props.mode} items={items6} />;
  } else {
    //受講者メニュー
    return <Menu mode={props.mode} items={items1} />;
  }
}

export default LeftMenu;
